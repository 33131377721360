import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import http from '../../infrastructure/api/http';
import { Customer } from '../../domain/entities/Customer';
import { imageFromBuffer } from '../../shared/utils/imageFromBuffer';
import DeleteIcon from '@mui/icons-material/Delete';

type FormData = {
  name: string;
	glosa: string;
  photo: FileList | null;
};

type Props = {
  onCreated?: () => void;
  onCancel?: () => void;
  customer?: Customer | null;
};

const CustomerForm: FC<Props> = ({ onCreated, onCancel, customer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(
    null
  );
  const {
    register,
    handleSubmit,
    setValue,
		watch,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!previewImage) {
      alert('Debes subir una imagen');
      return;
    }
    setError(null);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('glosa', data.glosa);
    if (data.photo && data.photo.length > 0)
      formData.append('photo', data.photo[0]);

    try {
      setIsLoading(true);
      if (!customer) {
        await http.post('/customers', formData);
      } else {
        await http.put(`/customers/${customer.id}`, formData);
      }
      onCreated?.();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }

    // Aquí puedes realizar acciones con los datos del formulario, como enviarlos a un servidor
  };

  useEffect(() => {
    setPreviewImage(null);
    if (customer) {
      setValue('name', customer.name);
      setValue('glosa', customer.glosa);
      setPreviewImage(imageFromBuffer(customer.image?.data || []));
    } else {
      setValue('name', '');
      setValue('glosa', '');
    }
  }, [customer]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} sx={{ px: 1, mt: 1, width: '100%' }}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5">
            Cliente
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {error && (
            <Box
              style={{ display: 'flex', justifyContent: 'center', padding: 15 }}
            >
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nombre"
            {...register('name', { required: 'El nombre es requerido' })}
            error={!!errors.name}
            helperText={errors.name?.message}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
					<Select
						label="Glosa"
						{...register('glosa', { required: 'El nombre es requerido' })}
						value={watch('glosa') || ''}
						error={!!errors.glosa}
						fullWidth
					>
						<MenuItem value={'Centro'}>Centro</MenuItem>
						<MenuItem value={'Instalación'}>Instalación</MenuItem>
						<MenuItem value={'Planta'}>Planta</MenuItem>
					</Select>
        </Grid>
        {previewImage && (
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img src={previewImage as string} alt="Logo" width="200" />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <input
            type="file"
            accept=".png,.jpeg"
            style={{ display: 'none' }}
            id="photo-upload"
            onChange={(file) => {
              if ((file?.target?.files || [])?.length > 0) {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                  setPreviewImage(reader.result);
                };
                if (file?.target?.files && file?.target?.files[0] !== null) {
                  reader.readAsDataURL(file?.target?.files[0]);
                  setValue('photo', file?.target?.files);
                }
              }
            }}
          />
          <label htmlFor="photo-upload">
            <Button
              component="span"
              variant="outlined"
              color="primary"
              sx={{ textTransform: 'none' }}
            >
              {customer?.image
                ? 'Reemplazar foto'
                : 'Adjuntar foto (PNG o JPEG)'}
            </Button>
          </label>
          {previewImage && (
            <IconButton
              type="button"
              aria-label="delete"
              onClick={() => {
                setPreviewImage(null);
                setValue('photo', null);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {errors.photo && (
            <p style={{ color: 'red', marginTop: '0.5rem' }}>
              {errors.photo.message}
            </p>
          )}
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{ textTransform: 'none' }}
          >
            {isLoading && <CircularProgress size={14} color="inherit" />}
            &nbsp;Enviar
          </Button>
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            sx={{ textTransform: 'none' }}
            onClick={() => {
              onCancel?.();
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerForm;
