import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Module } from '../../domain/entities/Module';
import { LampStatus } from '../../shared/enums/lamp-status.enum';
import { Lamp } from '../../domain/entities/Lamp';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { FC } from 'react';
import { Center } from '../../domain/entities/Center';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import { selectUser } from '../../domain/store/session/selectors';
import { User } from '../../domain/entities/User';
import { Jail } from '../../domain/entities/Jail';

moment.tz.setDefault('America/Santiago');

const generateColorByLampStatus = (
  center: Center,
  lamp: Lamp,
  user: User,
  status: any[]
): { bgColor: string; color: string } => {
  const configLamp = user.configs.filter(
    (config) =>
      config.centerId === center.id && config.variableId === 1 && config.status
  );

  const getPotency =
    status.length > 0
      ? Math.round(
          ((status[0].vfa * status[0].cfa +
            status[0].vfb * status[0].cfb +
            status[0].vfc * status[0].cfc) /
            1000) *
            100
        ) / 100
      : 0;

  if (getPotency === 0) return { bgColor: '#34495e', color: 'white' };
  if (parseFloat(lamp.amperageToroide) === 0)
    return { bgColor: '#e74c3c', color: 'white' };

  if (configLamp.length > 0) {
    const valueOperation =
      configLamp[0].multiplier * parseFloat(lamp.amperageToroide);
    if (
      valueOperation > configLamp[0].max ||
      valueOperation < configLamp[0].min
    )
      return { bgColor: '#e74c3c', color: 'white' };
    return { bgColor: '#27ae60', color: 'white' };
  } else {
    if (lamp.realStatus === LampStatus.ONLINE)
      return { bgColor: '#27ae60', color: 'white' };
    if (
      lamp.realStatus === LampStatus.OFFLINE ||
      lamp.realStatus === LampStatus.FAIL_DOWN
    )
      return { bgColor: '#e74c3c', color: 'white' };
    if (lamp.realStatus === LampStatus.FAIL_UP)
      return { bgColor: '#34495e', color: 'white' };
    if (lamp.realStatus === LampStatus.FAIL_DOWN)
      return { bgColor: '#d35400', color: 'black' };
    return { bgColor: 'black', color: 'white' };
  }
};

const generateColorByLamps = (lamps: Lamp[]): string => {
  const countOnline = lamps.filter(
    (lamp) => lamp.realStatus === LampStatus.ONLINE
  ).length;
  return lamps.length === countOnline ? 'transparent' : '#d35400';
};

type Props = {
  module: Module;
  selectedCenter: Center | null;
  status: any[];
};

const ModuleJail: FC<Props> = ({ module, selectedCenter, status }) => {
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const [totalLamps, setTotalLamps] = useState(8);
  const currentUser = useSelector(selectUser);

  const isJailFailStatus = (jail: Jail) => {
    return (
      moment().diff(moment(jail.updatedAt || jail.createdAt), 'minutes') >=
        10 || jail.alarms === 0
    );
  };

  useEffect(() => {
    let totalJailLamps = 0;
    module.jails?.forEach((j) => {
      if (j.lamps!.length > totalJailLamps) {
        totalJailLamps = j.lamps!.length;
      }
    });
    setTotalLamps(totalJailLamps);
  }, [module]);

  return (
    <Card>
      <CardContent sx={{ p: '10px', overflow: 'auto' }}>
        <Grid container spacing={'5px'}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={600}>
              {module.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#009581' }} />
          </Grid>
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'end'}
            flexDirection={'row'}
            gap={1}
            marginTop={'5px'}
          >
            <Box
              component="div"
              display="flex"
              flexDirection={'row'}
              gap={'2px'}
              alignItems={'center'}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#27ae60',
                  borderRadius: '50%',
                }}
              ></div>
              <Typography variant="subtitle1" fontSize={9}>
                lámpara encendida
              </Typography>
            </Box>
            <Box
              component="div"
              display="flex"
              flexDirection={'row'}
              gap={'2px'}
              alignItems={'center'}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#e74c3c',
                  borderRadius: '50%',
                }}
              ></div>
              <Typography variant="subtitle1" fontSize={9}>
                lámpara apagada
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <table className="w-full">
              <thead>
                <tr>
                  <th>
                    <Typography>JAULA</Typography>
                  </th>
                  <th>
                    <Typography>COM</Typography>
                  </th>
                  {Array.from(Array(totalLamps).keys()).map((l) => (
                    <th key={l}>
                      <Typography>L{l + 1}</Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {module?.jails?.map((jail) => (
                  <tr key={jail.id}>
                    <td align="center">
                      <Box
                        component="div"
                        p={'5px'}
                        sx={{
                          bgcolor: generateColorByLamps(jail.lamps || []),
                          borderRadius: '5px',
                        }}
                      >
                        {(selectedCenter?.fantasy1 || 0) > 0 ? (
                          (selectedCenter?.fantasy1 || 0) + parseInt(jail.name)
                        ) : (
                          <Typography variant="body1">{jail.name}</Typography>
                        )}
                      </Box>
                    </td>
                    <td align="center">
                      {isJailFailStatus(jail) ? (
                        <Tooltip
                          title={`Última actualización: ${moment(
                            jail.updatedAt || jail.createdAt
                          ).format('YYYY-MM-DD HH:mm:ss')}`}
                        >
                          <WifiOffIcon style={{ color: 'yellow' }} />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={`Última actualización: ${moment(
                            jail.updatedAt || jail.createdAt
                          ).format('YYYY-MM-DD HH:mm:ss')}`}
                        >
                          <WifiIcon style={{ color: 'green' }} />
                        </Tooltip>
                      )}
                    </td>
                    {(jail.lamps || []).map((lamp) => (
                      <td
                        key={lamp.id}
                        style={{
                          width: '40px',
                          height: '40px',
                          padding: '5px',
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Tooltip title={lamp.document}>
                            <>
                              <Box
                                component="div"
                                style={{
                                  backgroundColor: generateColorByLampStatus(
                                    selectedCenter!,
                                    lamp,
                                    currentUser!,
                                    status
                                  ).bgColor,
                                  border: `1px solid ${
                                    generateColorByLampStatus(
                                      selectedCenter!,
                                      lamp,
                                      currentUser!,
                                      status
                                    ).bgColor
                                  }`,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  color: generateColorByLampStatus(
                                    selectedCenter!,
                                    lamp,
                                    currentUser!,
                                    status
                                  ).color,
                                  borderRadius: '50%',
                                  width: isSmallScreen ? '10px' : '13px',
                                  height: isSmallScreen ? '10px' : '13px',
                                  position: 'relative',
                                }}
                              >
                                {isJailFailStatus(jail) && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      width: '100%',
                                      border: '1px solid red',
                                      top: '3.5px',
                                    }}
                                  ></div>
                                )}
                              </Box>
                              {module.visible_lamp_info && (
                                <Typography variant="body1" fontSize={9}>
                                  {isJailFailStatus(jail) ? '-' : lamp.amperageToroide}
                                </Typography>
                              )}
                            </>
                          </Tooltip>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ModuleJail;
