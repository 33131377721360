import { FC, useCallback, useEffect, useState } from 'react';
import http from '../../infrastructure/api/http';
import { useSelector } from 'react-redux';
import {
  selectCustomer,
  selectUser,
} from '../../domain/store/session/selectors';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { LampStatus } from '../../shared/enums/lamp-status.enum';
import { Customer } from '../../domain/entities/Customer';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import CachedIcon from '@mui/icons-material/Cached';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import { User } from '../../domain/entities/User';

moment.tz.setDefault('America/Santiago');

type Props = {
  onSelectCenter?: (center: any) => void;
  customerId: number;
};

type Lamp = {
  id: number;
  document: string;
  amperageToroide: string;
  realStatus: LampStatus;
  status: boolean;
  createdAt: string;
  updatedAt: string;
};

type Jail = {
  name: string;
  createdAt: string;
  updatedAt: string;
  id: number;
  alarms: number;
  alerts: number;
  lamps: Lamp[];
  status: boolean;
};

type Statuses = {
  id: number;
  createdAt: string;
  updatedAt: string;
  vfa: number;
  vfb: number;
  vfc: number;
  cfa: number;
  cfb: number;
  cfc: number;
  cne: number;
  pat: number;
  pafa: number;
  pafb: number;
  pafc: number;
  prt: number;
  prfa: number;
  prfb: number;
  prfc: number;
  papt: number;
  papa: number;
  papb: number;
  papc: number;
  fpt: number;
  fpfa: number;
  fpfb: number;
  fpfc: number;
  vlfa: number;
  vlfb: number;
  vlfc: number;
  ffa: number;
  ffb: number;
  ffc: number;
  eads: number;
  eadi: number;
  eais: number;
  eaii: number;
  erds: number;
  erdi: number;
  eris: number;
  eriii: number;
  realStatus: boolean;
};

type Center = {
  id: number;
  name: string;
  statuses: Statuses[];
  jails: Jail[];
  customer: Customer;
};

const calculeLastModified = (center: any) => {
  let result = 'Sin datos';
  const statuses = center.statuses
    .filter((st: any) => st.showInLamps)
    .filter((st: any) => st.status);

  if (statuses.length > 0) {
    if (statuses[statuses.length - 1].updatedAt) {
      result = statuses[statuses.length - 1].updatedAt;
    } else {
      result = statuses[statuses.length - 1].createdAt;
    }
  }

  if (moment(result).isValid()) {
    const now = moment().tz('America/Santiago');
    const startDate = moment(result).tz('America/Santiago');

    // Calcular la diferencia de tiempo
    const timeDiff = moment.duration(now.diff(startDate));

    // Obtener los componentes de tiempo individuales
    const years = timeDiff.years();
    const months = timeDiff.months();
    const weeks = timeDiff.weeks();
    const days = timeDiff.days();
    const hours = timeDiff.hours();
    const minutes = timeDiff.minutes();
    const seconds = timeDiff.seconds();

    if (years > 0) {
      return `hace ${years} año(s)`;
    } else if (months > 0) {
      return `hace ${months} mes(es)`;
    } else if (weeks > 0) {
      return `hace ${weeks} semana(s)`;
    } else if (days > 0) {
      return `hace ${days} dia(s)`;
    } else if (hours > 0) {
      return `hace ${hours} hora(s)`;
    } else if (minutes > 0) {
      return `hace ${minutes} minuto(s)`;
    } else {
      return `hace ${seconds} segundo(s)`;
    }
  }

  return result;
};

const DashboardManager: FC<Props> = ({ onSelectCenter, customerId }) => {
  const currentUser = useSelector(selectUser);
  const currentCustomer = useSelector(selectCustomer);
  const [centers, setCenters] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showLampReport, setShowLampReport] = useState(false);
  const isSmallScreen = useSelector(selectIsSmallScreen);

  const handleCron = async () => {
    try {
      setIsLoading(true);
      await http.post(`/customers/run-cron/${customerId}`);
      await new Promise((resolve) => setTimeout(resolve, 1500));
      await loadData();
    } catch (error) {
      alert('[Error] No es posible actualizar');
    } finally {
      setIsLoading(false);
    }
  };

  const isJailFailStatus = (jail: Jail) => {
    return (
      moment().diff(moment(jail.updatedAt || jail.createdAt), 'minutes') >=
        10 || jail.alarms === 0
    );
  };

  const getStatusPercent = useCallback(
    (center: Center): number => {
      const isLampValid = (lamp: Lamp, configs: any[]) => {
        if (!lamp.status) return false;
        const configLamp = configs.find(
          (config) =>
            config.variableId === 1 &&
            config.status &&
            config.centerId === center.id
        );
        if (configLamp) {
          const valueOperation =
            configLamp.multiplier * parseFloat(lamp.amperageToroide);
          if (
            valueOperation > configLamp.max ||
            valueOperation < configLamp.min
          )
            return false;
        }
        return (
          lamp.realStatus === LampStatus.ONLINE &&
          parseFloat(lamp.amperageToroide) > 0
        );
      };

      const userConfigs = currentUser?.configs || [];
      const activeJails = center.jails.filter((jail) => jail.status);

      if (activeJails.length === 0) return 0;

      let validLamps = 0;
      let totalLamps = 0;

      activeJails.forEach((jail) => {
        const lamps = jail.lamps.filter((lamp) => lamp.status);
        totalLamps += lamps.length;
        validLamps += lamps.filter((lamp) =>
          isLampValid(lamp, userConfigs)
        ).length;
      });

      if (totalLamps === 0) return 0;

      const validPercentage = (validLamps / totalLamps) * 100;

      return Math.round(validPercentage);
    },
    [currentUser]
  );

  const loadData = async () => {
    setCenters([]);
    setIsLoading(true);
    try {
      const response = await http.get<any[]>(
        `/customers/${customerId}/centers/details`
      );
      setCenters(response.data);
    } catch (error: any) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getPotency = (status: Statuses | null) => {
    if (!status) return 0;
    if (
      moment().diff(moment(status.updatedAt || status.createdAt), 'minutes') >=
      10
    )
      return 0;
    if (!status.realStatus) return 0;
      return (
        Math.round(
          ((status.vfa * status.cfa +
            status.vfb * status.cfb +
            status.vfc * status.cfc) /
            1000) *
            100
        ) / 100
      );
  };

  useEffect(() => {
    if (currentUser && customerId) {
      (async () => {
        await loadData();
      })();
    }
  }, [currentUser, customerId]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, 300000);

    return () => {
      clearInterval(interval);
    };
  }, [customerId]);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer sx={{ borderRadius: '15px' }}>
            <Table
              sx={{
                minWidth: 650,
                backgroundColor: 'rgba(0,0,0,0.09)',
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ borderBottom: '1px solid #ddd' }}>
                <TableRow
                  style={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <TableCell align="center">Empresa</TableCell>
                  <TableCell align="center">
                    {currentCustomer?.glosa ?? 'Centro'}
                  </TableCell>
                  {!currentUser?.isElectricOnly && (
                    <TableCell align="center">Estado</TableCell>
                  )}
                  {!currentUser?.isElectricOnly && (
                    <TableCell align="center">N° lámparas</TableCell>
                  )}
                  {!currentUser?.isElectricOnly && (
                    <TableCell align="center">N° jaulas</TableCell>
                  )}
                  {!currentUser?.isElectricOnly && (
                    <TableCell align="center">Lámparas ON</TableCell>
                  )}
                  {!currentUser?.isElectricOnly && (
                    <TableCell align="center">Lámparas OFF</TableCell>
                  )}
                  <TableCell align="center">Potencia</TableCell>
                  <TableCell align="center">Actualizado</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableCell colSpan={9} align="center">
                    <CircularProgress size={14} color="inherit" />
                  </TableCell>
                )}
                {centers.map((center: Center, index) => (
                  <TableRow
                    key={`dm${center.id}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'td, th': { border: 0 },
                      backgroundColor:
                        index % 2 === 0
                          ? 'rgba(255,255,255,0.1)'
                          : 'transparent',
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {center.customer.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: '#4DADBC', cursor: 'pointer' }}
                      onClick={() => {
                        if (!currentUser?.isElectricOnly)
                          onSelectCenter?.(center);
                      }}
                    >
                      {center.name}
                    </TableCell>
                    {!currentUser?.isElectricOnly && (
                      <TableCell align="center">
                        <div
                          style={{
                            backgroundColor: currentUser
                              ? getPotency(
                                  center.statuses.filter((st: any) => st.status)
                                    .length > 0
                                    ? center.statuses.filter(
                                        (st: any) => st.status
                                      )[0]
                                    : null
                                ) === 0
                                ? '#028727'
                                : center.jails.filter((j) => j.status)
                                    .length ===
                                  center.jails.filter(
                                    (jail) =>
                                      !isJailFailStatus(jail) && jail.status
                                  ).length
                                ? getStatusPercent(center) <= 90
                                  ? '#F8D900'
                                  : '#028727'
                                : '#F8D900'
                              : '#ddd',
                            color: '#000',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 1,
                            fontWeight: '600',
                          }}
                        >
                          {center.jails.filter((j) => j.status).length > 0 &&
                          currentUser ? (
                            <>
                              {getPotency(
                                center.statuses.filter((st: any) => st.status)
                                  .length > 0
                                  ? center.statuses.filter(
                                      (st: any) => st.status
                                    )[0]
                                  : null
                              ) === 0
                                ? 0
                                : getStatusPercent(center)}
                            </>
                          ) : (
                            <>-</>
                          )}
                        </div>
                      </TableCell>
                    )}
                    {!currentUser?.isElectricOnly && (
                      <TableCell align="center">
                        {center.jails
                          .filter((j) => j.status)
                          .reduce(
                            (acc, curr) =>
                              acc + curr.lamps.filter((l) => l.status).length,
                            0
                          )}
                      </TableCell>
                    )}
                    {!currentUser?.isElectricOnly && (
                      <TableCell align="center">
                        {center.jails.filter((j) => j.status).length}
                      </TableCell>
                    )}
                    {!currentUser?.isElectricOnly && (
                      <TableCell align="center">
                        {center.jails
                          .filter((j) => j.status)
                          .filter(
                            () =>
                              getPotency(
                                center.statuses.filter((st: any) => st.status)
                                  .length > 0
                                  ? center.statuses.filter(
                                      (st: any) => st.status
                                    )[0]
                                  : null
                              ) !== 0
                          )
                          .reduce(
                            (acc, curr) =>
                              acc +
                              curr.lamps.filter((lamp) => {
                                if (!lamp.status) return false;
                                const configLamp = currentUser?.configs.filter(
                                  (config) =>
                                    config.centerId === center.id &&
                                    config.variableId === 1 &&
                                    config.status
                                );
                                if (configLamp && configLamp.length > 0) {
                                  const valueOperation =
                                    configLamp[0].multiplier *
                                    parseFloat(lamp.amperageToroide);
                                  if (
                                    valueOperation > configLamp[0].max ||
                                    valueOperation < configLamp[0].min
                                  )
                                    return false;
                                }
                                return (
                                  lamp.realStatus === LampStatus.ONLINE &&
                                  parseFloat(lamp.amperageToroide) > 0
                                );
                              }).length,
                            0
                          )}
                      </TableCell>
                    )}
                    {!currentUser?.isElectricOnly && (
                      <TableCell align="center">
                        {center.jails
                          .filter((j) => j.status)
                          .reduce(
                            (acc, curr) =>
                              acc +
                              curr.lamps.filter((lamp) => {
                                if (
                                  getPotency(
                                    center.statuses.filter(
                                      (st: any) => st.status
                                    ).length > 0
                                      ? center.statuses.filter(
                                          (st: any) => st.status
                                        )[0]
                                      : null
                                  ) === 0
                                )
                                  return true;
                                if (!lamp.status) return false;
                                const configLamp = currentUser?.configs.filter(
                                  (config) =>
                                    config.centerId === center.id &&
                                    config.variableId === 1 &&
                                    config.status
                                );
                                if (configLamp && configLamp.length > 0) {
                                  const valueOperation =
                                    configLamp[0].multiplier *
                                    parseFloat(lamp.amperageToroide);
                                  if (
                                    valueOperation > configLamp[0].max ||
                                    valueOperation < configLamp[0].min
                                  )
                                    return true;
                                }
                                return (
                                  lamp.realStatus !== LampStatus.ONLINE ||
                                  parseFloat(lamp.amperageToroide) === 0
                                );
                              }).length,
                            0
                          )}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {center.statuses.filter((st: any) => st.status).length >
                      0 ? (
                        <>
                          {getPotency(
                            center.statuses.filter((st: any) => st.status)[0]
                          )}{' '}
                          kW
                        </>
                      ) : (
                        <> - </>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2">
                        {calculeLastModified(center)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={handleCron}
                        size="small"
                        sx={{
                          padding: '5px',
                          borderRadius: '50%',
                          background:
                            'linear-gradient(135deg, rgba(35, 69, 72, 1), rgba(68, 70, 73, 0.9))',
                        }}
                      >
                        <CachedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* <Grid item xs={12} md={3} xl={2}>
          <Box
            sx={{
              backgroundColor: 'rgba(255,255,255,0.1)',
              padding: 2,
              borderRadius: 2,
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowLampReport(true);
            }}
          >
            <Stack spacing={2}>
              <Stack direction="row" spacing={3} alignItems={'center'}>
                <Box
                  sx={{
                    backgroundColor: 'red',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                  }}
                ></Box>
                <Typography color="#4DADBC">
                  ALARMAS{' '}
                  {centers.reduce((prev, curr) => {
                    return (
                      prev +
                      curr.jails.reduce(
                        (jprev: number, jcurr: any) => jprev + jcurr.alarms,
                        0
                      )
                    );
                  }, 0)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={3} alignItems={'center'}>
                <Box
                  sx={{
                    backgroundColor: '#F8D900',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                  }}
                ></Box>
                <Typography color="#4DADBC">
                  WARNINGS{' '}
                  {centers.reduce((prev, curr) => {
                    return (
                      prev +
                      curr.jails.reduce(
                        (jprev: number, jcurr: any) => jprev + jcurr.alerts,
                        0
                      )
                    );
                  }, 0)}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid> */}
      </Grid>
      <Modal
        open={showLampReport}
        onClose={() => {
          setShowLampReport(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmallScreen ? '80%' : '50%',
            bgcolor: '#c2c2c2',
            boxShadow: 24,
            borderRadius: '15px',
            color: '#000',
            p: 2,
          }}
        >
          <table width="100%">
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Centro</th>
                <th>N° lámparas con error</th>
              </tr>
            </thead>
            <tbody>
              {centers.map((center) => (
                <tr key={center.id}>
                  <td align="center">{center.customer.name}</td>
                  <td align="center">{center.name}</td>
                  <td align="center">
                    {center.jails
                      .filter((j: any) => j.status)
                      .reduce(
                        (acc: number, curr: any) =>
                          acc +
                          curr.lamps.filter(
                            (lamp: any) =>
                              (lamp.realStatus === LampStatus.OFFLINE ||
                                lamp.realStatus === LampStatus.FAIL_DOWN) &&
                              lamp.status
                          ).length,
                        0
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Modal>
    </Box>
  );
};

export default DashboardManager;
